import axios from '@/common/axios'
import qs from 'qs'

export function addUser (data) {
  return axios({
    method: 'post',
    url: '/user/add',
    data: qs.stringify(data)
  })
}

export function deleteUser (id) {
  return axios({
    method: 'delete',
    url: '/user/delete/' + id
  })
}

export function updateUser (data) {
  return axios({
    method: 'put',
    url: '/user/update',
    data: qs.stringify(data)
  })
}

export function selectUserInfo (id) {
  return axios({
    method: 'get',
    url: '/user/info/' + id
  })
}

export function selectUserList (query) {
  return axios({
    method: 'get',
    url: '/user/list',
    params: query
  })
}

export function changePassword (data) {
  return axios({
    method: 'put',
    url: '/user/changePassword',
    data: qs.stringify(data)
  })
}

export function rolePermissionMenuTree () {
  return axios({
    method: 'get',
    url: '/user/rolePermissionMenuTree'
  })
}
