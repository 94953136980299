<template>
  <div id="handDisinfection">
    <el-dialog
      :title="handDisinfectionFormTitle"
      width="360px"
      :visible.sync="handDisinfectionDialogVisible"
      :close-on-click-modal="false"
      @close="handDisinfectionDialogClose"
    >
      <el-form
        ref="handDisinfectionFormRef"
        :model="handDisinfectionForm"
        :rules="handDisinfectionFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="消毒日期" prop="disinfectionDate">
          <el-date-picker v-model="handDisinfectionForm.disinfectionDate" placeholder="请选择消毒日期" value-format="yyyy-MM-dd" />
        </el-form-item>
        <el-form-item label="消毒时间" prop="disinfectionTime">
          <el-time-picker v-model="handDisinfectionForm.disinfectionTime" placeholder="请输入消毒时间" value-format="HH:mm" />
        </el-form-item>
        <el-form-item label="消毒剂" prop="type">
          <el-radio-group v-model="handDisinfectionForm.type">
            <el-radio :label="1">
              新洁尔灭
            </el-radio>
            <el-radio :label="2">
              酒精
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="消毒人员" prop="disinfectionPersonnel">
          <el-table
            ref="userTable"
            :data="userList"
            row-key="id"
            :cell-style="{padding: '6px'}"
            :header-cell-style="{padding: '6px', background: '#f8f8f9'}"
            :height="300"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column prop="realName" label="姓名" />
          </el-table>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="handDisinfectionDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="handDisinfectionFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="消毒日期">
        <el-date-picker v-model="searchForm.disinfectionDate" placeholder="请选择消毒日期" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['SCSB_HAND_DISINFECTION_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="handDisinfectionPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column label="消毒日期">
        <template slot-scope="scope">
          <span v-if="scope.row.disinfectionDate">{{ scope.row.disinfectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="disinfectionTime" label="消毒时间" />
      <el-table-column label="消毒剂">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">新洁尔灭</span>
          <span v-if="scope.row.type === 2">酒精</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="disinfectionPersonnel" label="消毒人员" /> -->
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['SCSB_HAND_DISINFECTION_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['SCSB_HAND_DISINFECTION_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="handDisinfectionPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addHandDisinfection,
  deleteHandDisinfection,
  updateHandDisinfection,
  selectHandDisinfectionInfo,
  selectHandDisinfectionList
} from '@/api/produce/handDisinfection'
import { selectUserList } from '@/api/system/user'

export default {
  data () {
    return {
      handDisinfectionDialogVisible: false,
      handDisinfectionFormTitle: '',
      handDisinfectionForm: {
        id: '',
        disinfectionDate: '',
        disinfectionTime: '',
        type: '',
        disinfectionPersonnel: ''
      },
      handDisinfectionFormRules: {
        disinfectionDate: [{ required: true, message: '消毒日期不能为空', trigger: ['blur', 'change']}]
      },
      handDisinfectionPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        disinfectionDate: ''
      },
      fileList: []
    }
  },
  created () {
    selectHandDisinfectionList(this.searchForm).then(res => {
      this.handDisinfectionPage = res
    })
    selectUserList({ deptId: window.g.PRODUCE_DEPT_ID }).then(res => {
      this.userList = res.list
    })
  },
  methods: {
    handDisinfectionDialogClose () {
      this.$refs.handDisinfectionFormRef.resetFields()
      this.$refs.userTable.clearSelection()
    },
    handDisinfectionFormSubmit () {
      if (this.handDisinfectionFormTitle === '手消毒记录详情') {
        this.handDisinfectionDialogVisible = false
        return
      }
      this.$refs.handDisinfectionFormRef.validate(async valid => {
        if (valid) {
          if (this.handDisinfectionFormTitle === '新增手消毒记录') {
            this.handDisinfectionForm.id = ''
            await addHandDisinfection(this.handDisinfectionForm)
          } else if (this.handDisinfectionFormTitle === '修改手消毒记录') {
            await updateHandDisinfection(this.handDisinfectionForm)
          }
          this.handDisinfectionPage = await selectHandDisinfectionList(this.searchForm)
          this.handDisinfectionDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.handDisinfectionFormTitle = '新增手消毒记录'
      this.handDisinfectionDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteHandDisinfection(row.id)
        if (this.handDisinfectionPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.handDisinfectionPage = await selectHandDisinfectionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.handDisinfectionFormTitle = '修改手消毒记录'
      this.handDisinfectionDialogVisible = true
      this.selectHandDisinfectionInfoById(row.id)
    },
    handleInfo (index, row) {
      this.handDisinfectionFormTitle = '手消毒记录详情'
      this.handDisinfectionDialogVisible = true
      this.selectHandDisinfectionInfoById(row.id)
    },
    selectHandDisinfectionInfoById (id) {
      selectHandDisinfectionInfo(id).then(res => {
        this.handDisinfectionForm.id = res.id
        this.handDisinfectionForm.disinfectionDate = res.disinfectionDate
        this.handDisinfectionForm.disinfectionTime = res.disinfectionTime
        this.handDisinfectionForm.type = res.type
        if (res.disinfectionPersonnel) {
          for (let id of res.disinfectionPersonnel.split(',')) {
            let user = this.userList.find(item => item.id === parseInt(id))
            this.$refs.userTable.toggleRowSelection(user)
          }
        }
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectHandDisinfectionList(this.searchForm).then(res => {
        this.handDisinfectionPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectHandDisinfectionList(this.searchForm).then(res => {
        this.handDisinfectionPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectHandDisinfectionList(this.searchForm).then(res => {
        this.handDisinfectionPage = res
      })
    },
    handleSelectionChange (value) {
      this.handDisinfectionForm.disinfectionPersonnel = value.map(item => item.id).join(',')
    }
  }
}
</script>

<style>
</style>
